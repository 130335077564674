import React from 'react';

export default function NotFound() {
  return (
    <div className="w-full flex justify-center items-center gap-4 font-basetica py-40 text-2xl">
      <span>404</span>
      <span>This page could not be found.</span>
    </div>
  );
}
